export type Question = {
  id: number;
  question: string;
  possibilities: QuestionPossibility[];
};

export type QuestionPossibility = {
  value: 0 | 1;
  answer: string;
};

export type QuizStorage = {
  questions: Question[];
  answers: Answer[];
};

export type Answer = {
  question: Question;
  answer: QuestionPossibility;
};

export const knowledgeQuestions: Question[] = [
  {
    id: 1,
    question: 'quiz.knowledge.book1.questions.q1.question',
    possibilities: [
      { answer: 'quiz.knowledge.book1.questions.q1.answer1', value: 1 },
      { answer: 'quiz.knowledge.book1.questions.q1.answer2', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q1.answer3', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q1.answer4', value: 0 },
    ],
  },
  {
    id: 2,
    question: 'quiz.knowledge.book1.questions.q2.question',
    possibilities: [
      { answer: 'quiz.knowledge.book1.questions.q2.answer1', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q2.answer2', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q2.answer3', value: 1 },
      { answer: 'quiz.knowledge.book1.questions.q2.answer4', value: 0 },
    ],
  },
  {
    id: 3,
    question: 'quiz.knowledge.book1.questions.q3.question',
    possibilities: [
      { answer: 'quiz.knowledge.book1.questions.q3.answer1', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q3.answer2', value: 1 },
      { answer: 'quiz.knowledge.book1.questions.q3.answer3', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q3.answer4', value: 0 },
    ],
  },
  {
    id: 4,
    question: 'quiz.knowledge.book1.questions.q4.question',
    possibilities: [
      { answer: 'quiz.knowledge.book1.questions.q4.answer1', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q4.answer2', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q4.answer3', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q4.answer4', value: 1 },
    ],
  },
  {
    id: 5,
    question: 'quiz.knowledge.book1.questions.q5.question',
    possibilities: [
      { answer: 'quiz.knowledge.book1.questions.q5.answer1', value: 1 },
      { answer: 'quiz.knowledge.book1.questions.q5.answer2', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q5.answer3', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q5.answer4', value: 0 },
    ],
  },
  {
    id: 6,
    question: 'quiz.knowledge.book1.questions.q6.question',
    possibilities: [
      { answer: 'quiz.knowledge.book1.questions.q6.answer1', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q6.answer2', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q6.answer3', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q6.answer4', value: 1 },
    ],
  },
  {
    id: 7,
    question: 'quiz.knowledge.book1.questions.q7.question',
    possibilities: [
      { answer: 'quiz.knowledge.book1.questions.q7.answer1', value: 1 },
      { answer: 'quiz.knowledge.book1.questions.q7.answer2', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q7.answer3', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q7.answer4', value: 0 },
    ],
  },
  {
    id: 8,
    question: 'quiz.knowledge.book1.questions.q8.question',
    possibilities: [
      { answer: 'quiz.knowledge.book1.questions.q8.answer1', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q8.answer2', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q8.answer3', value: 1 },
      { answer: 'quiz.knowledge.book1.questions.q8.answer4', value: 0 },
    ],
  },
  {
    id: 9,
    question: 'quiz.knowledge.book1.questions.q9.question',
    possibilities: [
      { answer: 'quiz.knowledge.book1.questions.q9.answer1', value: 1 },
      { answer: 'quiz.knowledge.book1.questions.q9.answer2', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q9.answer3', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q9.answer4', value: 0 },
    ],
  },
  {
    id: 10,
    question: 'quiz.knowledge.book1.questions.q10.question',
    possibilities: [
      { answer: 'quiz.knowledge.book1.questions.q10.answer1', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q10.answer2', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q10.answer3', value: 1 },
      { answer: 'quiz.knowledge.book1.questions.q10.answer4', value: 0 },
    ],
  },
  {
    id: 11,
    question: 'quiz.knowledge.book1.questions.q11.question',
    possibilities: [
      { answer: 'quiz.knowledge.book1.questions.q11.answer1', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q11.answer2', value: 1 },
      { answer: 'quiz.knowledge.book1.questions.q11.answer3', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q11.answer4', value: 0 },
    ],
  },
  {
    id: 12,
    question: 'quiz.knowledge.book1.questions.q12.question',
    possibilities: [
      { answer: 'quiz.knowledge.book1.questions.q12.answer1', value: 1 },
      { answer: 'quiz.knowledge.book1.questions.q12.answer2', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q12.answer3', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q12.answer4', value: 0 },
    ],
  },
  {
    id: 13,
    question: 'quiz.knowledge.book1.questions.q13.question',
    possibilities: [
      { answer: 'quiz.knowledge.book1.questions.q13.answer1', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q13.answer2', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q13.answer3', value: 1 },
      { answer: 'quiz.knowledge.book1.questions.q13.answer4', value: 0 },
    ],
  },
  {
    id: 14,
    question: 'quiz.knowledge.book1.questions.q14.question',
    possibilities: [
      { answer: 'quiz.knowledge.book1.questions.q14.answer1', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q14.answer2', value: 1 },
      { answer: 'quiz.knowledge.book1.questions.q14.answer3', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q14.answer4', value: 0 },
    ],
  },
  {
    id: 15,
    question: 'quiz.knowledge.book1.questions.q15.question',
    possibilities: [
      { answer: 'quiz.knowledge.book1.questions.q15.answer1', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q15.answer2', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q15.answer3', value: 1 },
      { answer: 'quiz.knowledge.book1.questions.q15.answer4', value: 0 },
    ],
  },
  {
    id: 16,
    question: 'quiz.knowledge.book1.questions.q16.question',
    possibilities: [
      { answer: 'quiz.knowledge.book1.questions.q16.answer1', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q16.answer2', value: 1 },
      { answer: 'quiz.knowledge.book1.questions.q16.answer3', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q16.answer4', value: 0 },
    ],
  },
  {
    id: 17,
    question: 'quiz.knowledge.book1.questions.q17.question',
    possibilities: [
      { answer: 'quiz.knowledge.book1.questions.q17.answer1', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q17.answer2', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q17.answer3', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q17.answer4', value: 1 },
    ],
  },
  {
    id: 18,
    question: 'quiz.knowledge.book1.questions.q18.question',
    possibilities: [
      { answer: 'quiz.knowledge.book1.questions.q18.answer1', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q18.answer2', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q18.answer3', value: 1 },
      { answer: 'quiz.knowledge.book1.questions.q18.answer4', value: 0 },
    ],
  },
  {
    id: 19,
    question: 'quiz.knowledge.book1.questions.q19.question',
    possibilities: [
      { answer: 'quiz.knowledge.book1.questions.q19.answer1', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q19.answer2', value: 1 },
      { answer: 'quiz.knowledge.book1.questions.q19.answer3', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q19.answer4', value: 0 },
    ],
  },
  {
    id: 20,
    question: 'quiz.knowledge.book1.questions.q20.question',
    possibilities: [
      { answer: 'quiz.knowledge.book1.questions.q20.answer1', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q20.answer2', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q20.answer3', value: 1 },
      { answer: 'quiz.knowledge.book1.questions.q20.answer4', value: 0 },
    ],
  },
  {
    id: 21,
    question: 'quiz.knowledge.book1.questions.q21.question',
    possibilities: [
      { answer: 'quiz.knowledge.book1.questions.q21.answer1', value: 1 },
      { answer: 'quiz.knowledge.book1.questions.q21.answer2', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q21.answer3', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q21.answer4', value: 0 },
    ],
  },
  {
    id: 22,
    question: 'quiz.knowledge.book1.questions.q22.question',
    possibilities: [
      { answer: 'quiz.knowledge.book1.questions.q22.answer1', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q22.answer2', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q22.answer3', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q22.answer4', value: 1 },
    ],
  },
  {
    id: 23,
    question: 'quiz.knowledge.book1.questions.q23.question',
    possibilities: [
      { answer: 'quiz.knowledge.book1.questions.q23.answer1', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q23.answer2', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q23.answer3', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q23.answer4', value: 1 },
    ],
  },
  {
    id: 24,
    question: 'quiz.knowledge.book1.questions.q24.question',
    possibilities: [
      { answer: 'quiz.knowledge.book1.questions.q24.answer1', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q24.answer2', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q24.answer3', value: 1 },
      { answer: 'quiz.knowledge.book1.questions.q24.answer4', value: 0 },
    ],
  },
  {
    id: 25,
    question: 'quiz.knowledge.book1.questions.q25.question',
    possibilities: [
      { answer: 'quiz.knowledge.book1.questions.q25.answer1', value: 1 },
      { answer: 'quiz.knowledge.book1.questions.q25.answer2', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q25.answer3', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q25.answer4', value: 0 },
    ],
  },
  {
    id: 26,
    question: 'quiz.knowledge.book1.questions.q26.question',
    possibilities: [
      { answer: 'quiz.knowledge.book1.questions.q26.answer1', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q26.answer2', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q26.answer3', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q26.answer4', value: 1 },
    ],
  },
  {
    id: 27,
    question: 'quiz.knowledge.book1.questions.q27.question',
    possibilities: [
      { answer: 'quiz.knowledge.book1.questions.q27.answer1', value: 1 },
      { answer: 'quiz.knowledge.book1.questions.q27.answer2', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q27.answer3', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q27.answer4', value: 0 },
    ],
  },
  {
    id: 28,
    question: 'quiz.knowledge.book1.questions.q28.question',
    possibilities: [
      { answer: 'quiz.knowledge.book1.questions.q28.answer1', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q28.answer2', value: 1 },
      { answer: 'quiz.knowledge.book1.questions.q28.answer3', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q28.answer4', value: 0 },
    ],
  },
  {
    id: 29,
    question: 'quiz.knowledge.book1.questions.q29.question',
    possibilities: [
      { answer: 'quiz.knowledge.book1.questions.q29.answer1', value: 1 },
      { answer: 'quiz.knowledge.book1.questions.q29.answer2', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q29.answer3', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q29.answer4', value: 0 },
    ],
  },
  {
    id: 30,
    question: 'quiz.knowledge.book1.questions.q30.question',
    possibilities: [
      { answer: 'quiz.knowledge.book1.questions.q30.answer1', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q30.answer2', value: 1 },
      { answer: 'quiz.knowledge.book1.questions.q30.answer3', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q30.answer4', value: 0 },
    ],
  },
  {
    id: 31,
    question: 'quiz.knowledge.book1.questions.q31.question',
    possibilities: [
      { answer: 'quiz.knowledge.book1.questions.q31.answer1', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q31.answer2', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q31.answer3', value: 1 },
      { answer: 'quiz.knowledge.book1.questions.q31.answer4', value: 0 },
    ],
  },
  {
    id: 32,
    question: 'quiz.knowledge.book1.questions.q32.question',
    possibilities: [
      { answer: 'quiz.knowledge.book1.questions.q32.answer1', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q32.answer2', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q32.answer3', value: 1 },
      { answer: 'quiz.knowledge.book1.questions.q32.answer4', value: 0 },
    ],
  },
  {
    id: 33,
    question: 'quiz.knowledge.book1.questions.q33.question',
    possibilities: [
      { answer: 'quiz.knowledge.book1.questions.q33.answer1', value: 1 },
      { answer: 'quiz.knowledge.book1.questions.q33.answer2', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q33.answer3', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q33.answer4', value: 0 },
    ],
  },
  {
    id: 34,
    question: 'quiz.knowledge.book1.questions.q34.question',
    possibilities: [
      { answer: 'quiz.knowledge.book1.questions.q34.answer1', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q34.answer2', value: 1 },
      { answer: 'quiz.knowledge.book1.questions.q34.answer3', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q34.answer4', value: 0 },
    ],
  },
  {
    id: 35,
    question: 'quiz.knowledge.book1.questions.q35.question',
    possibilities: [
      { answer: 'quiz.knowledge.book1.questions.q35.answer1', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q35.answer2', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q35.answer3', value: 1 },
      { answer: 'quiz.knowledge.book1.questions.q35.answer4', value: 0 },
    ],
  },
  {
    id: 36,
    question: 'quiz.knowledge.book1.questions.q36.question',
    possibilities: [
      { answer: 'quiz.knowledge.book1.questions.q36.answer1', value: 1 },
      { answer: 'quiz.knowledge.book1.questions.q36.answer2', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q36.answer3', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q36.answer4', value: 0 },
    ],
  },
  {
    id: 37,
    question: 'quiz.knowledge.book1.questions.q37.question',
    possibilities: [
      { answer: 'quiz.knowledge.book1.questions.q37.answer1', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q37.answer2', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q37.answer3', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q37.answer4', value: 1 },
    ],
  },
  {
    id: 38,
    question: 'quiz.knowledge.book1.questions.q38.question',
    possibilities: [
      { answer: 'quiz.knowledge.book1.questions.q38.answer1', value: 1 },
      { answer: 'quiz.knowledge.book1.questions.q38.answer2', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q38.answer3', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q38.answer4', value: 0 },
    ],
  },
  {
    id: 39,
    question: 'quiz.knowledge.book1.questions.q39.question',
    possibilities: [
      { answer: 'quiz.knowledge.book1.questions.q39.answer1', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q39.answer2', value: 1 },
      { answer: 'quiz.knowledge.book1.questions.q39.answer3', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q39.answer4', value: 0 },
    ],
  },
  {
    id: 40,
    question: 'quiz.knowledge.book1.questions.q40.question',
    possibilities: [
      { answer: 'quiz.knowledge.book1.questions.q40.answer1', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q40.answer2', value: 1 },
      { answer: 'quiz.knowledge.book1.questions.q40.answer3', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q40.answer4', value: 0 },
    ],
  },
  {
    id: 41,
    question: 'quiz.knowledge.book1.questions.q41.question',
    possibilities: [
      { answer: 'quiz.knowledge.book1.questions.q41.answer1', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q41.answer2', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q41.answer3', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q41.answer4', value: 1 },
    ],
  },
  {
    id: 42,
    question: 'quiz.knowledge.book1.questions.q42.question',
    possibilities: [
      { answer: 'quiz.knowledge.book1.questions.q42.answer1', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q42.answer2', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q42.answer3', value: 1 },
      { answer: 'quiz.knowledge.book1.questions.q42.answer4', value: 0 },
    ],
  },
  {
    id: 43,
    question: 'quiz.knowledge.book1.questions.q43.question',
    possibilities: [
      { answer: 'quiz.knowledge.book1.questions.q43.answer1', value: 1 },
      { answer: 'quiz.knowledge.book1.questions.q43.answer2', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q43.answer3', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q43.answer4', value: 0 },
    ],
  },
  {
    id: 44,
    question: 'quiz.knowledge.book1.questions.q44.question',
    possibilities: [
      { answer: 'quiz.knowledge.book1.questions.q44.answer1', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q44.answer2', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q44.answer3', value: 1 },
      { answer: 'quiz.knowledge.book1.questions.q44.answer4', value: 0 },
    ],
  },
  {
    id: 45,
    question: 'quiz.knowledge.book1.questions.q45.question',
    possibilities: [
      { answer: 'quiz.knowledge.book1.questions.q45.answer1', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q45.answer2', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q45.answer3', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q45.answer4', value: 1 },
    ],
  },
  {
    id: 46,
    question: 'quiz.knowledge.book1.questions.q46.question',
    possibilities: [
      { answer: 'quiz.knowledge.book1.questions.q46.answer1', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q46.answer2', value: 1 },
      { answer: 'quiz.knowledge.book1.questions.q46.answer3', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q46.answer4', value: 0 },
    ],
  },
  {
    id: 47,
    question: 'quiz.knowledge.book1.questions.q47.question',
    possibilities: [
      { answer: 'quiz.knowledge.book1.questions.q47.answer1', value: 1 },
      { answer: 'quiz.knowledge.book1.questions.q47.answer2', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q47.answer3', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q47.answer4', value: 0 },
    ],
  },
  {
    id: 48,
    question: 'quiz.knowledge.book1.questions.q48.question',
    possibilities: [
      { answer: 'quiz.knowledge.book1.questions.q48.answer1', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q48.answer2', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q48.answer3', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q48.answer4', value: 1 },
    ],
  },
  {
    id: 49,
    question: 'quiz.knowledge.book1.questions.q49.question',
    possibilities: [
      { answer: 'quiz.knowledge.book1.questions.q49.answer1', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q49.answer2', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q49.answer3', value: 1 },
      { answer: 'quiz.knowledge.book1.questions.q49.answer4', value: 0 },
    ],
  },
  {
    id: 50,
    question: 'quiz.knowledge.book1.questions.q50.question',
    possibilities: [
      { answer: 'quiz.knowledge.book1.questions.q50.answer1', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q50.answer2', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q50.answer3', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q50.answer4', value: 1 },
    ],
  },
  {
    id: 51,
    question: 'quiz.knowledge.book1.questions.q51.question',
    possibilities: [
      { answer: 'quiz.knowledge.book1.questions.q51.answer1', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q51.answer2', value: 0 },
      { answer: 'quiz.knowledge.book1.questions.q51.answer3', value: 1 },
      { answer: 'quiz.knowledge.book1.questions.q51.answer4', value: 0 },
    ],
  },
];
