import cosmologuesImg from '~assets/img/cosmologues.png?w=300&format=avif&quality=100&imagetoolsurl';
import psychicsImg from '~assets/img/psychics.png?w=300&format=avif&quality=100&imagetoolsurl';
import terreImg from '~assets/img/terre.png?w=300&format=avif&quality=100&imagetoolsurl';
import feuImg from '~assets/img/feu.png?w=300&format=avif&quality=100&imagetoolsurl';
import maruosImg from '~assets/img/maruos.png?w=300&format=avif&quality=100&imagetoolsurl';
import spectrauxImg from '~assets/img/spectraux.png?w=300&format=avif&quality=100&imagetoolsurl';
import morphoImg from '~assets/img/morpho.png?w=300&format=avif&quality=100&imagetoolsurl';
import eauImg from '~assets/img/eau.png?w=300&format=avif&quality=100&imagetoolsurl';
import airImg from '~assets/img/air.png?w=300&format=avif&quality=100&imagetoolsurl';
import herboImg from '~assets/img/herboristes.png?w=300&format=avif&quality=100&imagetoolsurl';
import lancesImg from '~assets/img/3-lances.png?w=300&format=avif&quality=100&imagetoolsurl';
import matraquesImg from '~assets/img/matraques.png?w=300&format=avif&quality=100&imagetoolsurl';
import dolmenImg from '~assets/img/dolmen.png?w=300&format=avif&quality=100&imagetoolsurl';
import dragonImg from '~assets/img/dragon.png?w=300&format=avif&quality=100&imagetoolsurl';
import trinquetraImg from '~assets/img/trinquetra.png?w=300&format=avif&quality=100&imagetoolsurl';
import glaiveImg from '~assets/img/glaive.png?w=300&format=avif&quality=100&imagetoolsurl';
import magicaeBWImg from '~assets/img/magicae-bw.png?w=300&format=avif&quality=100&imagetoolsurl';
import collierImg from '~assets/img/collier.png?w=300&format=avif&quality=100&imagetoolsurl';
import prismeImg from '~assets/img/prisme.png?w=300&format=avif&quality=100&imagetoolsurl';
import pipeImg from '~assets/img/pipe.png?w=300&format=avif&quality=100&imagetoolsurl';
import navireImg from '~assets/img/navire.png?w=300&format=avif&quality=100&imagetoolsurl';
import doigtsCroisesImg from '~assets/img/doigts-croises.png?w=300&format=avif&quality=100&imagetoolsurl';
import feeImg from '~assets/img/fee.png?w=300&format=avif&quality=100&imagetoolsurl';
import floconImg from '~assets/img/flocon.png?w=300&format=avif&quality=100&imagetoolsurl';
import trollImg from '~assets/img/troll.png?w=300&format=avif&quality=100&imagetoolsurl';
import pierreObsidienneImg from '~assets/img/pierre-obsidienne.png?w=300&format=avif&quality=100&imagetoolsurl';
import flaconImg from '~assets/img/flacon-de-potion.png?w=300&format=avif&quality=100&imagetoolsurl';
import grimoireImg from '~assets/img/grimoire.png?w=300&format=avif&quality=100&imagetoolsurl';

export type GlossaryItemType = {
  name: string;
  description: string;
  img300: string;
  drawBy: string;
};

export const clans1to5: GlossaryItemType[] = [
  {
    name: 'clans.maruos.displayName',
    description: 'clans.maruos.description',
    img300: maruosImg,
    drawBy: 'drawers.drawByJulieRabischung',
  },
  {
    name: 'clans.spectral.displayName',
    description: 'clans.spectral.description',
    img300: spectrauxImg,
    drawBy: 'drawers.drawByJulieRabischung',
  },
  {
    name: 'clans.fire.displayName',
    description: 'clans.fire.description',
    img300: feuImg,
    drawBy: 'drawers.drawByJulieRabischung',
  },
  {
    name: 'clans.earth.displayName',
    description: 'clans.earth.description',
    img300: terreImg,
    drawBy: 'drawers.drawByJulieRabischung',
  },
  {
    name: 'clans.water.displayName',
    description: 'clans.water.description',
    img300: eauImg,
    drawBy: 'drawers.drawByJulieRabischung',
  },
];
export const clans6to10: GlossaryItemType[] = [
  {
    name: 'clans.air.displayName',
    description: 'clans.air.description',
    img300: airImg,
    drawBy: 'drawers.drawByJulieRabischung',
  },
  {
    name: 'clans.morphos.displayName',
    description: 'clans.morphos.description',
    img300: morphoImg,
    drawBy: 'drawers.drawByJulieRabischung',
  },
  {
    name: 'clans.herbalists.displayName',
    description: 'clans.herbalists.description',
    img300: herboImg,
    drawBy: 'drawers.drawByJulieRabischung',
  },
  {
    name: 'clans.cosmologist.displayName',
    description: 'clans.cosmologist.description',
    img300: cosmologuesImg,
    drawBy: 'drawers.drawByJulieRabischung',
  },
  {
    name: 'clans.psychics.displayName',
    description: 'clans.psychics.description',
    img300: psychicsImg,
    drawBy: 'drawers.drawByJulieRabischung',
  },
];

export const allClans: GlossaryItemType[] = [...clans1to5, ...clans6to10];

export const institutions1To5: GlossaryItemType[] = [
  {
    name: 'institutions.militum.displayName',
    description: 'institutions.militum.description',
    img300: lancesImg,
    drawBy: 'drawers.drawByJulieRabischung',
  },
  {
    name: 'institutions.magistratus.displayName',
    description: 'institutions.magistratus.description',
    img300: matraquesImg,
    drawBy: 'drawers.drawByJulieRabischung',
  },
  {
    name: 'institutions.iter.displayName',
    description: 'institutions.iter.description',
    img300: dolmenImg,
    drawBy: 'drawers.drawByJulieRabischung',
  },
  {
    name: 'institutions.imperium.displayName',
    description: 'institutions.imperium.description',
    img300: dragonImg,
    drawBy: 'drawers.drawByJulieRabischung',
  },
  {
    name: 'institutions.liberandum.displayName',
    description: 'institutions.liberandum.description',
    img300: trinquetraImg,
    drawBy: 'drawers.drawByJulieRabischung',
  },
];

export const institutions6To10: GlossaryItemType[] = [
  {
    name: 'illustrations.cercle.displayName',
    description: 'illustrations.cercle.description',
    img300: glaiveImg,
    drawBy: 'drawers.drawByJulieRabischung',
  },
];

export const allInstitutions: GlossaryItemType[] = [...institutions1To5, ...institutions6To10];

export const world1To5: GlossaryItemType[] = [
  {
    name: 'illustrations.magicae.displayName',
    description: 'illustrations.magicae.description',
    img300: magicaeBWImg,
    drawBy: 'drawers.drawByJulieRabischung',
  },
  {
    name: 'illustrations.collierDuGardien.displayName',
    description: 'illustrations.collierDuGardien.description',
    img300: collierImg,
    drawBy: 'drawers.drawByJulieRabischung',
  },
  {
    name: 'illustrations.prisme.displayName',
    description: 'illustrations.prisme.description',
    img300: prismeImg,
    drawBy: 'drawers.drawByJulieRabischung',
  },
  {
    name: 'illustrations.pipe.displayName',
    description: 'illustrations.pipe.description',
    img300: pipeImg,
    drawBy: 'drawers.drawByJulieRabischung',
  },
  {
    name: 'illustrations.solvit.displayName',
    description: 'illustrations.solvit.description',
    img300: navireImg,
    drawBy: 'drawers.drawByJulieRabischung',
  },
];

export const world6To10: GlossaryItemType[] = [
  {
    name: 'illustrations.salut.displayName',
    description: 'illustrations.salut.description',
    img300: doigtsCroisesImg,
    drawBy: 'drawers.drawByJulieRabischung',
  },
  {
    name: 'illustrations.brinos.displayName',
    description: 'illustrations.brinos.description',
    img300: feeImg,
    drawBy: 'drawers.drawByJulieRabischung',
  },
  {
    name: 'illustrations.ledaju.displayName',
    description: 'illustrations.ledaju.description',
    img300: floconImg,
    drawBy: 'drawers.drawByJulieRabischung',
  },
  {
    name: 'illustrations.troll.displayName',
    description: 'illustrations.troll.description',
    img300: trollImg,
    drawBy: 'drawers.drawByJulieRabischung',
  },
  {
    name: 'illustrations.pierre.displayName',
    description: 'illustrations.pierre.description',
    img300: pierreObsidienneImg,
    drawBy: 'drawers.drawByJulieRabischung',
  },
];

export const world11To15: GlossaryItemType[] = [
  {
    name: 'illustrations.potion.displayName',
    description: 'illustrations.potion.description',
    img300: flaconImg,
    drawBy: 'drawers.drawByJulieRabischung',
  },
  {
    name: 'illustrations.registre.displayName',
    description: 'illustrations.registre.description',
    img300: grimoireImg,
    drawBy: 'drawers.drawByJulieRabischung',
  },
];

export const allWorld: GlossaryItemType[] = [...world1To5, ...world6To10, ...world11To15];
