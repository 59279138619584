import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import type { GlossaryItemType } from '~models/glossary';

interface Props {
  item: GlossaryItemType;
  handleClose: () => void;
}

function InformationDisplay({ item, handleClose }: Props) {
  // Setup translate
  const { t } = useTranslation();

  return (
    <Dialog fullWidth open onClose={handleClose} maxWidth="sm">
      <DialogTitle style={{ textAlign: 'center' }} variant="h4">
        {t(item.name)}
      </DialogTitle>
      <DialogContent dividers>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            m: 'auto',
            alignItems: 'center',
          }}
        >
          <img
            src={item.img300}
            alt={t(item.name)}
            style={{ maxHeight: 300, maxWidth: 300, width: '100%' }}
            loading="lazy"
          />
        </Box>
        <Typography
          sx={{
            textAlign: 'center',
            fontStyle: 'italic',
            fontSize: '0.8rem',
            marginBottom: '10px',
          }}
        >
          {t(item.drawBy)}
        </Typography>
        <DialogContentText style={{ textAlign: 'justify' }}>{t(item.description)}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={handleClose}>
          {t('common.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default InformationDisplay;
