import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import type { GlossaryItemType } from '~models/glossary';

interface Props {
  item: GlossaryItemType;
  setInfoDisplayItem: (i: GlossaryItemType) => void;
}

function GlossaryCard({ item, setInfoDisplayItem }: Props) {
  // Setup translate
  const { t } = useTranslation();

  const learnMore = () => {
    setInfoDisplayItem(item);
  };

  return (
    <Grid item xs={8} sm={4} lg={3} xl={2} style={{ width: '100%' }}>
      <Card style={{ height: '100%' }}>
        <div style={{ display: 'flex', width: '100%', alignItems: 'center', flexFlow: 'column' }}>
          <CardMedia
            component="img"
            image={item.img300}
            alt={t(item.name)}
            sx={{
              padding: '15px',
              cursor: 'pointer',
            }}
            onClick={learnMore}
          />
        </div>
        <CardHeader
          title={t(item.name)}
          subheader={
            <Button size="small" style={{ textTransform: 'none' }} onClick={learnMore}>
              {t('common.learnMore')}
            </Button>
          }
          sx={{ textAlign: 'center' }}
        />
      </Card>
    </Grid>
  );
}

export default GlossaryCard;
