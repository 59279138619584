import React, { ReactNode } from 'react';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';

interface Props {
  children: string | ReactNode;
  sx?: SxProps;
  typographyProps?: TypographyProps;
}

function TitleTypography({ children, sx = {}, typographyProps = {} }: Props) {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Typography
        variant="h2"
        gutterBottom
        sx={{ textAlign: 'center', textTransform: 'capitalize', margin: '50px 0', ...sx }}
        {...typographyProps}
      >
        {children}
        <Box
          component="span"
          sx={(th: Theme) => ({
            margin: `${th.spacing(1)} auto 0`,
            backgroundColor: th.palette.text.primary,
          })}
          style={{
            height: 4,
            width: '80%',
            left: '10%',
            display: 'block',
          }}
        />
      </Typography>
    </div>
  );
}

export default TitleTypography;
