import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import SvgIcon from '@mui/material/SvgIcon';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import { siAmazon } from 'simple-icons';
import { useTheme } from '@mui/material';
import TitleTypography from '~components/TitleTypography';
import couvertureFrontImg from '~assets/img/couverture-front.png?w=256&format=avif&quality=100&imagetoolsurl';

const gridItemProps = {
  xl: 6,
  lg: 6,
  md: 6,
  sm: 12,
  xs: 12,
  sx: { display: 'flex', justifyContent: 'center' },
};

const volume1Url = 'https://www.amazon.fr/Clans-Magicae-B%C3%A9reng%C3%A8re-Sophie-Croysez/dp/2959013506/';

function Volumes() {
  // Setup translate
  const { t } = useTranslation();
  // Get theme
  const theme = useTheme();

  return (
    <>
      <TitleTypography typographyProps={{ id: 'buy' }}>{t('home.volumesTitle')}</TitleTypography>
      <Grid container sx={{ padding: '0 20px' }}>
        <Grid item {...gridItemProps}>
          <Card sx={{ margin: '10px', padding: '10px', maxWidth: '800px', width: '100%' }}>
            <CardMedia sx={{ paddingTop: '16px' }}>
              <a href={volume1Url} target="_blank" rel="noreferrer">
                <Box
                  component="img"
                  src={couvertureFrontImg}
                  alt={t('home.volume1Title')}
                  sx={{
                    borderRadius: '4px',
                    border: '1px solid',
                    borderColor: theme.palette.divider,
                    '&:hover': {
                      boxShadow: '0px 0px 129px 24px rgba(221,224,223,0.2)',
                    },
                    height: {
                      xs: '300px',
                      sm: '500px',
                    },
                    transition: 'box-shadow 0.5s ease',
                  }}
                />
              </a>
            </CardMedia>
            <CardHeader
              title={
                <Link href={volume1Url} target="_blank" color="inherit" underline="hover">
                  {t('home.volume1Title')}
                </Link>
              }
            />
            <CardContent>
              <Typography variant="body2">{t('home.volume1Description')}</Typography>
              <IconButton component="a" href={volume1Url} target="_blank" sx={{ marginTop: '20px' }}>
                <SvgIcon>
                  <path d={siAmazon.path} />
                </SvgIcon>
              </IconButton>
            </CardContent>
          </Card>
        </Grid>
        <Grid item {...gridItemProps}>
          <Card sx={{ margin: '10px', padding: '10px', maxWidth: '800px', width: '100%' }}>
            <CardMedia sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '16px' }}>
              <div
                style={{
                  display: 'flex',
                  borderRadius: '4px',
                  border: '1px solid',
                  borderColor: theme.palette.divider,
                  width: 'fit-content',
                  position: 'relative',
                }}
              >
                <Box
                  component="img"
                  src={couvertureFrontImg}
                  alt={t('home.volume2Title')}
                  sx={{
                    borderRadius: '4px',
                    border: '1px solid',
                    borderColor: theme.palette.divider,
                    opacity: 0.3,
                    boxShadow:
                      'rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;',
                    height: {
                      xs: '300px',
                      sm: '500px',
                    },
                  }}
                />
                <Typography sx={{ top: '50%', position: 'absolute', left: 'calc(50% - 60px)', fontStyle: 'italic' }}>
                  {t('home.soon')}
                </Typography>
              </div>
            </CardMedia>
            <CardHeader title={t('home.volume2Title')} />
            <CardContent>
              <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                {t('home.volume2Description')}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default Volumes;
