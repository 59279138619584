// Copied from https://github.com/sindresorhus/array-move/blob/main/index.js
// eslint-disable-next-line import/prefer-default-export
export function arrayMoveItem<T>(array: T[], fromIndex: number, toIndex: number) {
  const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;

    const [item] = array.splice(fromIndex, 1);
    array.splice(endIndex, 0, item);
  }
}

export function getRandomSubarray<T>(arr: T[], size: number) {
  const shuffled = arr.slice(0);
  let i = arr.length;
  let temp;
  let index;

  // eslint-disable-next-line no-plusplus
  while (i--) {
    index = Math.floor((i + 1) * Math.random());
    temp = shuffled[index];
    shuffled[index] = shuffled[i];
    shuffled[i] = temp;
  }

  return shuffled.slice(0, size);
}
