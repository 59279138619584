import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import SvgIcon from '@mui/material/SvgIcon';
import IconButton from '@mui/material/IconButton';
import { siTiktok, siInstagram } from 'simple-icons';
import TitleTypography from '~components/TitleTypography';
import authorImg from '~assets/img/author.jpg?w=256&h=256&format=avif&quality=100&imagetoolsurl';

function Author() {
  // Setup translate
  const { t } = useTranslation();

  return (
    <>
      <TitleTypography typographyProps={{ id: 'author' }}>{t('home.authorTitle')}</TitleTypography>
      <Card
        sx={{
          display: 'flex',
          padding: { xs: '20px', sm: '40px' },
          maxWidth: '80%',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar src={authorImg} sx={{ width: { xs: '200px', sm: '256px' }, height: { xs: '200px', sm: '256px' } }} />
        <CardHeader title={t('home.authorName')} />
        <CardContent>
          <Typography variant="h6" style={{ marginBottom: '10px' }}>
            {t('home.authorDescriptionTitle')}
          </Typography>
          <Typography variant="body2">{t('home.authorDescriptionPart1')}</Typography>
          <Typography variant="body2">{t('home.authorDescriptionPart2')}</Typography>
          <Typography variant="body2">{t('home.authorDescriptionPart3')}</Typography>
          <Typography variant="body2">{t('home.authorDescriptionPart4')}</Typography>
          <Typography variant="h6" style={{ margin: '10px 0' }}>
            {t('home.authorMoreTitle')}
          </Typography>
          <Typography variant="body2">{t('home.authorMorePart1')}</Typography>
          <Typography variant="body2">{t('home.authorMorePart2')}</Typography>
          <Typography variant="body2">{t('home.authorMorePart3')}</Typography>
          <Typography variant="body2">{t('home.authorMorePart4')}</Typography>
          <Typography variant="body2">{t('home.authorMorePart5')}</Typography>
        </CardContent>
        <div style={{ marginTop: '10px' }}>
          <IconButton
            href="https://www.tiktok.com/@berengere.s.croysez"
            target="_blank"
            style={{ marginRight: '40px' }}
          >
            <SvgIcon>
              <path d={siTiktok.path} />
            </SvgIcon>
          </IconButton>
          <IconButton href="https://www.instagram.com/berengere.croysez" target="_blank">
            <SvgIcon>
              <path d={siInstagram.path} />
            </SvgIcon>
          </IconButton>
        </div>
      </Card>
    </>
  );
}

export default Author;
