import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from '@mui/material/Card';
import TitleTypography from '~components/TitleTypography';
import KnowledgeQuiz from './components/KnowledgeQuiz';

function Quiz() {
  // Setup translate
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <TitleTypography>{t('quiz.title')}</TitleTypography>
      <Card
        sx={{
          display: 'flex',
          width: { xs: '300px', sm: '500px', md: '700px' },
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <KnowledgeQuiz />
      </Card>
    </div>
  );
}

export default Quiz;
