import React from 'react';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import type { SxProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import magicaeImg from '~assets/img/magicae-color.png?w=50&format=avif&quality=100&imagetoolsurl';

export interface Props {
  containerBoxSx?: SxProps;
  typographyProps?: TypographyProps;
}

function Footer({ containerBoxSx = {}, typographyProps = {} }: Props) {
  // Setup translate
  const { t } = useTranslation();

  // Get current year from now date
  const currentYear = new Date().getFullYear();

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        textAlign: 'center',
        flexDirection: 'column',
        margin: '50px 10px',
        ...containerBoxSx,
      }}
    >
      <img src={magicaeImg} alt={t('illustrations.magicae.displayName')} style={{ width: '50px', margin: '20px' }} />
      <Stack direction="row" spacing={2} sx={{ display: 'flex', marginBottom: '10px' }}>
        <Link
          component={RouterLink}
          to="/contact"
          sx={{
            color: (theme) => theme.palette.text.primary,
            textDecorationColor: 'inherit',
            width: '60px',
          }}
        >
          {t('footer.contactLink')}
        </Link>
        <Link
          component={RouterLink}
          to="/legal-mentions"
          sx={{ color: (theme) => theme.palette.text.primary, textDecorationColor: 'inherit', width: '60px' }}
        >
          {t('footer.legalMentionsLink')}
        </Link>
      </Stack>
      <Typography sx={{ display: 'flex' }} {...typographyProps}>
        {t('footer.basedOn')}
      </Typography>
      <Typography sx={{ display: 'flex' }} {...typographyProps}>
        {t('footer.textAndIdeas')}
      </Typography>
      <Typography sx={{ display: 'flex' }} {...typographyProps}>
        {t('footer.copyright', { year: currentYear })}
      </Typography>
    </Box>
  );
}

export default Footer;
