import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ErrorBoundary } from 'react-error-boundary';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles';
import FallbackErrorBoundary from '~components/FallbackErrorBoundary';
import MainPageCenterLoading from '~components/MainPageCenterLoading';
import TopBar from '~components/TopBar';
import ScrollOnTop from '~components/ScrollOnTop';
import Footer from '~components/Footer';

const theme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {
  return (
    <Suspense fallback={<MainPageCenterLoading />}>
      <ErrorBoundary FallbackComponent={FallbackErrorBoundary}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <TopBar />
          <ScrollOnTop />
          <Outlet />
          <Footer />
        </MuiThemeProvider>
      </ErrorBoundary>
    </Suspense>
  );
}

export default App;
