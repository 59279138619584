import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import {
  clans1to5,
  clans6to10,
  allClans,
  GlossaryItemType,
  world1To5,
  allWorld,
  institutions1To5,
  allInstitutions,
  world6To10,
  world11To15,
  institutions6To10,
} from '~models/glossary';
import TitleTypography from '~components/TitleTypography';
import Card from './components/Card';
import InformationDisplay from './components/InformationDisplay';

const now = Date.now();
const unlock1 = new Date(2024, 8, 11, 18, 30).getTime();
const unlock2 = new Date(2024, 8, 18, 18, 30).getTime();

function Glossary() {
  // Setup translate
  const { t } = useTranslation();
  // Get theme
  const theme = useTheme();
  // Check if it is matching
  const matchesXL = useMediaQuery(theme.breakpoints.up('xl'));

  // Build clans lists
  const clansLists = matchesXL ? [clans1to5, clans6to10] : [allClans];
  const worldLists = matchesXL ? [world1To5, world6To10, world11To15] : [allWorld];
  const institutionsLists = matchesXL ? [institutions1To5, institutions6To10] : [allInstitutions];

  // States
  const [infoDisplayItem, setInfoDisplayItem] = useState<GlossaryItemType | undefined>();

  return (
    <>
      <TitleTypography>{t('glossary.clansTitle')}</TitleTypography>
      {clansLists.map((list) => (
        <Grid justifyContent="center" direction="row" container spacing={2} style={{ marginBottom: '20px' }}>
          {list.map((cl) => (
            <Card item={cl} setInfoDisplayItem={setInfoDisplayItem} />
          ))}
        </Grid>
      ))}
      <div style={{ margin: '50px 0', display: now > unlock1 ? 'block' : 'none' }}>
        <TitleTypography>{t('glossary.institutionsTitle')}</TitleTypography>
        {institutionsLists.map((list) => (
          <Grid justifyContent="center" direction="row" container spacing={2} style={{ marginBottom: '20px' }}>
            {list.map((cl) => (
              <Card item={cl} setInfoDisplayItem={setInfoDisplayItem} />
            ))}
          </Grid>
        ))}
      </div>
      <div style={{ margin: '50px 0', display: now > unlock2 ? 'block' : 'none' }}>
        <TitleTypography>{t('glossary.worldTitle')}</TitleTypography>
        {worldLists.map((list) => (
          <Grid justifyContent="center" direction="row" container spacing={2} style={{ marginBottom: '20px' }}>
            {list.map((cl) => (
              <Card item={cl} setInfoDisplayItem={setInfoDisplayItem} />
            ))}
          </Grid>
        ))}
      </div>
      {infoDisplayItem && (
        <InformationDisplay
          item={infoDisplayItem}
          handleClose={() => {
            setInfoDisplayItem(undefined);
          }}
        />
      )}
    </>
  );
}

export default Glossary;
