import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import type { Answer, Question, QuestionPossibility } from '~routes/Quiz/data/data';

interface Props {
  question: Question;
  handleNext?: (r: Answer) => void;
  answerDisplay?: Answer;
}

function QuestionDisplay({ question, handleNext = () => {}, answerDisplay = undefined }: Props) {
  // Setup translate
  const { t } = useTranslation();

  const [answer, setAnswer] = useState<QuestionPossibility>();

  return (
    <FormControl sx={{ m: 3 }} variant="standard">
      <Typography sx={{ marginBottom: '5px' }}>{t(question.question)}</Typography>
      <RadioGroup
        name="quiz"
        onChange={(_, v) => {
          if (answerDisplay) {
            return;
          }

          const a = question.possibilities.find((it) => it.answer === v);
          setAnswer(() => a);
        }}
      >
        {question.possibilities.map((it) => (
          <FormControlLabel
            key={it.answer}
            value={it.answer}
            control={<Radio />}
            label={t(it.answer)}
            color={it.value !== 0 ? 'success' : 'inherit'}
          />
        ))}
      </RadioGroup>
      {!answerDisplay && (
        <Button
          sx={{ mt: 1, mr: 1 }}
          type="submit"
          variant="outlined"
          disabled={!answer}
          onClick={() => {
            if (answer) {
              handleNext({ question, answer });
            }
          }}
        >
          {t('common.next')}
        </Button>
      )}
    </FormControl>
  );
}

export default QuestionDisplay;
