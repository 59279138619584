import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import etoileCouleurCercleImg from '~assets/img/etoile-couleur-cercle.png?w=500&format=avif&quality=100&imagetoolsurl';
import clansTitleImg from '~assets/img/les-clans-title.png?w=300&format=avif&quality=100&imagetoolsurl';
import cosmologuesImg from '~assets/img/cosmologues.png?w=80&format=avif&quality=100&imagetoolsurl';
import psychicsImg from '~assets/img/psychics.png?w=80&format=avif&quality=100&imagetoolsurl';
import terreImg from '~assets/img/terre.png?w=80&format=avif&quality=100&imagetoolsurl';
import feuImg from '~assets/img/feu.png?w=80&format=avif&quality=100&imagetoolsurl';
import maruosImg from '~assets/img/maruos.png?w=80&format=avif&quality=100&imagetoolsurl';
import spectrauxImg from '~assets/img/spectraux.png?w=80&format=avif&quality=100&imagetoolsurl';
import morphoImg from '~assets/img/morpho.png?w=80&format=avif&quality=100&imagetoolsurl';
import eauImg from '~assets/img/eau.png?w=80&format=avif&quality=100&imagetoolsurl';
import airImg from '~assets/img/air.png?w=80&format=avif&quality=100&imagetoolsurl';
import herboImg from '~assets/img/herboristes.png?w=80&format=avif&quality=100&imagetoolsurl';

const Item = styled('div')({
  '@keyframes autoRun': {
    from: {
      transform:
        'rotate(0deg) translateX(calc(sin(var(--position)* pi / var(--quantity)) * var(--step) * 1%)) translateY(calc(cos(var(--position)* pi / var(--quantity)) * var(--step) * 1%)) rotate(0deg)',
    },
    to: {
      transform:
        'rotate(360deg) translateX(calc(sin(var(--position)* pi / var(--quantity)) * var(--step) * 1%)) translateY(calc(cos(var(--position)* pi / var(--quantity)) * var(--step) * 1%)) rotate(-360deg)',
    },
  },
  position: 'absolute',
  animation: 'autoRun 60s linear infinite',
  inset: '0 0 0 0',
  transform:
    'translateX(calc(sin( var(--position)* pi / var(--quantity) ) * var(--step) * 1%)) translateY(calc(cos(var(--position)* pi / var(--quantity)) * var(--step) * 1%))',
});

function RotatingClansWithStar() {
  // Setup translate
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        width: '100%',
        textAlign: 'center',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <div>
        <Box
          component="img"
          src={etoileCouleurCercleImg}
          alt={t('illustrations.cercle.displayName')}
          sx={{ width: { xs: '300px', sm: '500px' } }}
        />
      </div>
      <img src={clansTitleImg} alt={t('glossary.clansTitle')} style={{ width: '300px' }} />
      <Box
        sx={{
          position: 'absolute',
          width: { xs: '50px', sm: '80px' },
          height: { xs: '50px', sm: '80px' },
          top: { xs: 'calc(50% - 50px)', sm: 'calc(50% - 65px)' },
          left: { xs: 'calc(50% - 25px)', sm: 'calc(50% - 40px)' },
          zIndex: 2,
          '--quantity': 5,
          '--step': { xs: 220, sm: 230 },
        }}
      >
        {/* @ts-expect-error I don't know */}
        <Item style={{ '--position': 1.5 }}>
          <img
            src={cosmologuesImg}
            alt={t('clans.cosmologist.displayName')}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        </Item>
        {/* @ts-expect-error I don't know */}
        <Item style={{ '--position': 2.5 }}>
          <img
            src={psychicsImg}
            alt={t('clans.psychics.displayName')}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        </Item>
        {/* @ts-expect-error I don't know */}
        <Item style={{ '--position': 3.5 }}>
          <img
            src={terreImg}
            alt={t('clans.earth.displayName')}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        </Item>
        {/* @ts-expect-error I don't know */}
        <Item style={{ '--position': 4.5 }}>
          <img
            src={feuImg}
            alt={t('clans.fire.displayName')}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        </Item>
        {/* @ts-expect-error I don't know */}
        <Item style={{ '--position': 5.5 }}>
          <img
            src={maruosImg}
            alt={t('clans.maruos.displayName')}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        </Item>
        {/* @ts-expect-error I don't know */}
        <Item style={{ '--position': 6.5 }}>
          <img
            src={spectrauxImg}
            alt={t('clans.spectral.displayName')}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        </Item>
        {/* @ts-expect-error I don't know */}
        <Item style={{ '--position': 7.5 }}>
          <img
            src={morphoImg}
            alt={t('clans.morphos.displayName')}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        </Item>
        {/* @ts-expect-error I don't know */}
        <Item style={{ '--position': 8.5 }}>
          <img
            src={eauImg}
            alt={t('clans.water.displayName')}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        </Item>
        {/* @ts-expect-error I don't know */}
        <Item style={{ '--position': 9.5 }}>
          <img
            src={airImg}
            alt={t('clans.air.displayName')}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        </Item>
        {/* @ts-expect-error I don't know */}
        <Item style={{ '--position': 10.5 }}>
          <img
            src={herboImg}
            alt={t('clans.herbalists.displayName')}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        </Item>
      </Box>
    </Box>
  );
}

export default RotatingClansWithStar;
