import React from 'react';
import ScrollToHashElement from '@cascadia-code/scroll-to-hash-element';
import RotatingClansWithStar from './components/RotatingClansWithStar';
import Volumes from './components/Volumes';
import Author from './components/Author';

function Home() {
  return (
    <>
      <ScrollToHashElement behavior="smooth" />
      <RotatingClansWithStar />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Author />
        <Volumes />
      </div>
    </>
  );
}

export default Home;
