import React from 'react';
import { useTranslation } from 'react-i18next';
import TitleTypography from '~components/TitleTypography';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

function LegalMention() {
  // Setup translate
  const { t } = useTranslation();

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <TitleTypography>{t('legalMention.title')}</TitleTypography>

      <Card
        sx={{
          display: 'flex',
          padding: { xs: '20px', sm: '40px' },
          maxWidth: '80%',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <CardContent>
          <Typography variant="h5">{t('legalMention.responsibleTitle')}</Typography>
          <Typography>Bérengère Croysez</Typography>
          <Typography>{t('legalMention.responsibleAddress')}</Typography>
          <Typography>
            {t('legalMention.responsibleContact')}:{' '}
            <Link href="mailto:contact@lesclans-saga.com">contact@lesclans-saga.com</Link>
          </Typography>
          <Typography variant="h5" style={{ marginTop: '10px' }}>
            {t('legalMention.textAndImageTitle')}
          </Typography>
          <Typography>{t('legalMention.textAndImageAllRights')}</Typography>
          <Typography>{t('legalMention.textAndImageText')}</Typography>
          <Typography>
            {t('legalMention.textAndImageImagePart1')}{' '}
            <Link href="https://www.julierabischung.com/">Julie Rabischung</Link>{' '}
            {t('legalMention.textAndImageImagePart2')}
          </Typography>
          <Typography>{t('legalMention.textAndImageRights')}</Typography>
          <Typography variant="h5" style={{ marginTop: '10px' }}>
            {t('legalMention.serverTitle')}
          </Typography>
          <Typography>{t('legalMention.serverText')}</Typography>
          <Typography variant="h5" style={{ marginTop: '10px' }}>
            {t('legalMention.webmasterTitle')}
          </Typography>
          <Typography>
            {t('legalMention.webmasterText')} (<Link href="https://github.com/oxyno-zeta/">Oxyno-zeta</Link>)
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
}

export default LegalMention;
