import React from 'react';
import { useTranslation } from 'react-i18next';
import TitleTypography from '~components/TitleTypography';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

function Contact() {
  // Setup translate
  const { t } = useTranslation();

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <TitleTypography>{t('contact.title')}</TitleTypography>

      <Card
        sx={{
          display: 'flex',
          padding: { xs: '20px', sm: '40px' },
          maxWidth: '80%',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <CardContent>
          <Typography>
            {t('contact.content')} <Link href="mailto:contact@lesclans-saga.com">contact@lesclans-saga.com</Link>
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
}

export default Contact;
